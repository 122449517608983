.home{
    
    height: 100%;
    width: 100%;
    padding-top: 10%;    
    .title{
        display: flex;
        justify-content: center;
        align-content: center;
        color:#fff;
        font-size: 45px;
    }

    .intro{
        width: 100%;
        display: flex;
    justify-content: center;
    align-content: center;
        color:#fff;
        font-size: 30px;
        display: flex;
        .names{
            color:orange
        }

    }

    .social{
        margin: 20px;
    }


    .source{
        font-size: 12px;
        color:white;
    }

}