.project-item {
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  min-width: 25vh;
  background-color: #fff;
  overflow: hidden;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  margin: 10px;
  cursor: pointer;
  box-shadow: "1px 3px 1px #9E9E9E";
  border-width: 2px;
  border-style: solid;
  border-color: rgb(219, 219, 219);
  .title {
    font-size: 20px;
    color: black;
    padding: 10px;
    text-decoration: none;
    background-color: white;
  }

  .image {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .image img {
    width: 100%;
  }

  &:hover {
    .preview {
      display: none;
      text-decoration: none;
    }

    .info {
      display: block;
      text-decoration: none;
    }

    border-radius: 40px;
  }

  .preview {
    height: 100%;
  }

  .info {
    height: 100%;
    width: 100%;
    display: none;
    justify-content: space-between;
    background-color: #6b8e23;
    text-decoration: none;
  }

  .description {
    font-size: 15px;
    color: #fff;
    overflow: hidden;
    padding: 15px;
    text-decoration: none;
  }

  .tags {
    background-color: #6b8e23;
    text-decoration: none;
  }
}

body {
  background-color: white;
}
