.App {
  text-align: center;
  height: 100%;
  min-height: 100vh;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  height: 100%;
  min-height: 100vh;

  background: linear-gradient(-45deg, #395e1aa6, #25310f, #06525f, #053952);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

a {
  text-decoration: none;
}

.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
