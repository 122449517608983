.about{
    background-color:white;
    height: 100%;
    min-height: 80vh;
    border-radius: 20px;
    margin:10px;

    .text{
        height: 80%;
    }
   
    .social{
        background-color: "red";
        text-align: center;
        padding: 20px;
        position: fixed;
        left: 0;
        bottom: 0;
        height: 20%;
        width: 100%;
    }
    
}

