.header {
  widows: 100%;
  min-width: 45vh;

  display: flex;
  justify-content: space-between;
  align-content: center;
  background-color: rgb(175, 93, 17);

  .options {
    width: 40%;
    display: flex;
    padding: 10px;
    justify-content: flex-end;
    align-content: center;

    .option {
      padding: 10px 15px;
      cursor: pointer;
      color: white;
      text-decoration: none;
      text-align: center;

      &:hover {
        background-color: rgb(236, 183, 35);
        border-radius: 5px;
      }
    }
  }

  .left-section {
    padding: 10px;
    justify-content: flex-end;
    align-content: center;
    .home {
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 5px;

      text-align: center;

      font-size: 18px;

      &:hover {
        font-size: 20px;
        border-radius: 5px;
      }
    }
  }
}
