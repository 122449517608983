.projects {
  background-color: white;
  height: 100%;
  min-height: 100vh;
  min-width: 25vh;
  background-color: white;
  border-top-left-radius: 40px;
  border-bottom-right-radius: 40px;
  margin: 20px;
  opacity: 0.95;
  h1 {
    color: #fff;
  }

  .items {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.progress {
  height: 100vh;
  justify-content: center;
  align-content: center;
  align-items: center;
}
