.contact {
  justify-content: center;
  align-content: center;
  align-items: center;
  min-width: 50vh;

  h1 {
    color: #fff;
  }
  .form {
    justify-content: center;
    align-content: center;
    width: 60%;
    margin-left: 20%;
    padding: 0px;
    .input {
      text-align: center;
      width: 80%;
      height: 100%;
      padding: 10px;
      border-radius: 10px;
      border-color: gray;
      border-width: 0px;
      margin: 5px;
    }
  }
}
