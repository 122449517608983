
.gallery-menu{
    display: flex;
    justify-content: center;
    align-content: center;
    
    .gallery-menu-item{
        background-color: white;
        border-color: green;
        border-width: 1px;
        color: black;
        border-radius: 30px;
        padding: 10px;
        margin: 2px;
        cursor: pointer;

        &:hover{
            background-color: green;
            color: white;
        }
    }

    .active{
        background-color: green;
        color: white;
    }
}