.project-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  min-width: 40vh;
  width: 100%;
  .menu-item {
    border-radius: 20px;
    background-color: white;
    color: black;
    padding: 10px;
    margin: 2px;

    &:hover {
      background-color: rgb(223, 163, 0);
      color: white;
      cursor: pointer;
    }
  }

  .active {
    background-color: rgb(175, 93, 17);
    color: white;
  }
}
