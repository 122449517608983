.resume {
  min-height: 80vh;

  display: flex;
  padding: 20px;
  flex-wrap: wrap;

  .left-panel {
    width: 25%;
    min-width: 30vh;
    border-radius: 10px;
    margin: 5px;
    opacity: 0.95;
      background-color: white;
}

  .detail {
    width: 70%;
    min-width: 40vh;
    background-color: white;
    margin: 5px;
    border-radius: 10px;
    opacity: 0.95;
  }
}
