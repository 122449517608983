.gallery {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;

  .menu {
    margin: 10px;
  }

  .stack {
    text-align: center;
    background-color: white;
    padding: 20px;
  }
}

.alice-carousel__stage-item  > img{
width: 100%;
object-fit: contain;
}