.side-panel-item{
    display: flex;
    justify-content:start ;
    align-content: center;
    border-radius: 10px;
    cursor: pointer;
    height: 60px;
    width: 100%;
    background-color: white;


    .name{
        font-size: 20px;
        padding:10px;
        text-align: start;
        width: 70%;
    }

    
    .image{
        font-size: 20px;
        padding:5px;
    }

     
    .icon{
        padding:5px;
        font-size: 20px;
    }

    &:hover{
        background-color: yellowgreen;
        color: white;
        .icon{
            padding:6px;
            color: white;
        }
        .arrow{
            color:yellowgreen;
        }
    }

    
    .arrow{
        font-size: 30px;
        padding:10px;
        width: 10%;
        color:white;
    }


}